import {
    useState,
} from 'react'
import {
    useMutation,
} from "@apollo/client";
import sendEmailMutation from './Reservation/sendClaimReceiptToEmailMutation'
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormGroup, FormControlLabel, Checkbox, Avatar
} from '@mui/material';
import AddEmailFormDialog from './AddEmailFormDialog'
import AlertSnackBar from '../../../Components/AlertSnackbar'

export default function EmailFormDialog({ open, onClose, reservationCode }) {
    const [inputs, setInputs] = useState({
        host: false,
        guest: false,
    })

    const [emails, setEmails] = useState([]);

    const [openSnackbar, setOpenSnackbar] = useState({
        open: false,
        severity: 'success',
        title: '',
        message: '',
    })

    const [isOpenEmailDialogBox, setIsOpenEmailDialogBox] = useState(false)

    const [
        updateEmail,
        { loading: mutationLoading, error: mutationError, data: mutationData }
    ] = useMutation(sendEmailMutation);


    const handleSubmit = event => {
        event.preventDefault()
        const emailArray = emails.map(i => i.mailId);
        console.log("emailArray", emailArray)
        console.log("isArray: ", Array.isArray(emailArray))
        console.log({
            variables: {
                reservationCode: reservationCode,
                willSendGuest: inputs.guest,
                willSendHost: inputs.host,
                additionalEmails: emailArray
            }
        })

        updateEmail({
            variables: {
                reservationCode: reservationCode,
                willSendGuest: inputs.guest,
                willSendHost: inputs.host,
                additionalEmails: emailArray
            }
        }).then(mutateData => {
            setEmails([])
            setInputs(() => ({
                host: false,
                guest: false,
            }))
            console.log(mutateData.data.sendClaimReceiptToEmail);
            if (mutateData.data.sendClaimReceiptToEmail === true) {
                setOpenSnackbar({
                    open: true,
                    severity: 'success',
                    title: 'Success',
                    message: 'mail successfully sent to recipients',
                })
                onClose()
            }
        })
            .catch(error => {
                console.log("error123: ", JSON.stringify(error))
                setOpenSnackbar({
                    open: true,
                    severity: 'error',
                    title: 'Error',
                    message: 'Please try again later.',
                })
            })
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar({
            open: false,
            severity: 'success',
            title: '',
            message: '',
        })
    }

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Reservation Information</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <FormGroup row={true}>
                            <FormControlLabel control={<Checkbox onChange={() => setInputs((prev) => ({
                                ...prev,
                                host: !inputs.host,
                            }))} />} label="Send to Host" />
                            <FormControlLabel control={<Checkbox onChange={() => setInputs((prev) => ({
                                ...prev,
                                guest: !inputs.guest,
                            }))} />} label="Send to Guest" />
                        </FormGroup>
                        {emails.map((email) => {
                            console.log(email)
                            return <div key={email.id}>
                                <Chip
                                    sx={{ mt: 0.5 }}
                                    label={email.mailId}
                                    avatar={<Avatar>{email.receiptType.charAt(0).toUpperCase()}</Avatar>}
                                    onDelete={() => {
                                        const id = email.id;
                                        const updatedEmails = emails.filter((email) => {
                                            return email.id !== id;
                                        });
                                        setEmails(updatedEmails);
                                        console.log(email)
                                    }}
                                />
                            </div>
                        })}
                        <DialogContentText>
                            <Button sx={{ mt: 1 }} variant="outlined" startIcon={<AddIcon />} onClick={() => {
                                setIsOpenEmailDialogBox(true)
                            }}>
                                Add Mail
                            </Button>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={
                            () => {
                                onClose();
                                setInputs(() => ({
                                    email: "",
                                    host: false,
                                    guest: false,
                                }))
                            }
                        }>Cancel</Button>
                        <LoadingButton
                            type='submit'
                            endIcon={<SendIcon />}
                            loading={mutationLoading}
                            loadingPosition="end"
                            variant="contained"
                            disabled={inputs.guest === true || inputs.host === true || !emails.length === false ? false : true}
                        >
                            <span>Send</span>
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
            {/* ------ email dialog ------ */}

            {<AddEmailFormDialog
                open={isOpenEmailDialogBox}
                onClose={(emailObj) => {
                    setIsOpenEmailDialogBox(false);
                    if (emailObj !== null && emailObj !== undefined) {
                        const updatedEmails = [emailObj, ...emails];
                        console.log("updatedEmails", updatedEmails)
                        setEmails(updatedEmails)
                    }

                }}
            />}

            {
                <AlertSnackBar open={openSnackbar.open} onClose={handleSnackbarClose} severity={openSnackbar.severity} title={openSnackbar.title} message={openSnackbar.message} />
            }
        </>


    );
}