import {
    Button,
    Container,
} from "@mui/material"

import {
    useNavigate,
  } from "react-router-dom"

export default () => {

    const navigate = useNavigate()

    return (
        <Container>
            <Button onClick={() => {navigate('./reservation')}} >Reservations</Button>
        </Container>
    )
}