
import {
  BrowserRouter,
} from "react-router-dom"

import Authenticator from './authenticator'

function App() {

  return (
    <BrowserRouter>
      <Authenticator />
    </BrowserRouter>
  )
}

export default App
