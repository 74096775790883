import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'

import {
  setContext,
} from '@apollo/client/link/context'

import {
  Auth,
} from 'aws-amplify'

const authLink = setContext(async (_, { headers }) => {

  const user = await Auth.currentSession()

  const authHeader = `Bearer ${user.idToken.jwtToken}`

  return {
    headers: {
      ...headers,
      Authentication: authHeader,
      Authorization: authHeader,
    },
  }
})

const httpLink = createHttpLink({
  uri: 'v2/gateway',
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export default client