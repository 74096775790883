import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from "@mui/material";

const ImageDialog = ({ open, onClose, imageUrl, imageTitle }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{imageTitle}</DialogTitle>
            <DialogContent>
                <img src={imageUrl} alt="Dialog" width="100%" />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageDialog;