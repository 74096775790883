export default {
    Auth: {

        // REQUIRED - Amazon Cognito Region
        region: 'us-west-2',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-west-2_HcWkXkNw4',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '44cr8jslu2a3bib7hrr9n76i89',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        signUpVerificationMethod: 'code', // 'code' | 'link' 

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_SRP_AUTH',

        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'vgo-admin-up-dev',
            scope: ['openid'],
            redirectSignIn: 'https://localhost:3000/',
            redirectSignOut: 'https://localhost:3000/',
            responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
            clientId: "44cr8jslu2a3bib7hrr9n76i89"
        }
    }
}