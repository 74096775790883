import {
    useState
} from 'react'

import {
    Container,
} from "@mui/material"

import SearchBar from "../../Components/SearchBar"

import ReservationView from './views/Reservation'


export default () => {

    const [reservationCode, setReservationCode] = useState()

    const handleSearch = (text) => {
        setReservationCode(text)
    }

    return (
        <Container
            sx={{
                padding: "1rem",
            }}
        >
            <SearchBar handleSearch={handleSearch} />
            <Container>
                {
                    reservationCode &&
                    <ReservationView
                        reservationCode={reservationCode}
                    />
                }
                {
                    !reservationCode &&
                    <div>
                        Search to monitor reservation
                    </div>
                }
            </Container>
        </Container>
    )
}