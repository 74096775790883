import React from 'react'
import {
    Alert, AlertTitle, Snackbar,
} from '@mui/material';

export default function AlertSnackBar({ open, onClose, severity, title, message }) {
    return (
        <Snackbar open={open} autoHideDuration={2000} onClose={onClose} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
            <Alert severity={severity}>
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    )
}