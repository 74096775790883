import { gql } from '@apollo/client'

export default gql`
mutation SendClaimReceiptEmail(
	$reservationCode: String!
	$willSendGuest: Boolean!
	$willSendHost: Boolean!
	$additionalEmails: [String!]
) {
	sendClaimReceiptToEmail(
		reservationCode: $reservationCode
		willSendGuest: $willSendGuest
		willSendHost: $willSendHost
		additionalEmails: $additionalEmails
	)
}
`;