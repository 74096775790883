import {
    useState,
} from 'react'
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import {
    Document,
    Page,
    pdfjs,
} from 'react-pdf'
import Button from '@mui/material/Button'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Stack } from '@mui/material'
import { useWindowWidth } from '@wojtekmaj/react-hooks'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
export default ({
    url,
}) => {

    const urlObject = new URL(url)
    const path = urlObject.pathname

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }
    const width = useWindowWidth();

    const requestPrefix = process.env.NODE_ENV === "production" ?
        "" :
        "assets"

    return (
        <>
            <Document
                file={`${requestPrefix}${path}`}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} renderTextLayer={false} width={Math.min(width * 0.9, 800)} />
            </Document>
            <div>
                <p>
                    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </p>
                <Stack direction='row' spacing={2}>
                    <Button
                        variant="contained"
                        endIcon={<ChevronLeftIcon />}
                        onClick={previousPage}
                        disabled={pageNumber <= 1}
                    >

                    </Button>
                    <Button
                        variant="contained"
                        endIcon={<ChevronRightIcon />}
                        onClick={nextPage}
                        disabled={pageNumber >= numPages}
                    >

                    </Button>
                </Stack>

            </div>
        </>
    )

}