import { gql } from '@apollo/client'

export default gql`
  query Reservation ($reservationCode: String!) {
    reservationByCode (reservationCode: $reservationCode) {
        id
        reservationCode
        hostClaimReceipt
        guestClaimReceipt
        insuranceCard {
          insuranceCardImages {
            frontImage
            rearImage
          }
        }
    }
  }

`