import {
  useState,
} from 'react'

import {
  TextField,
  Button,
  Stack,
  InputAdornment,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
export default ({ handleSearch }) => {
  const [searchText, setSearchText] = useState('')

  const handleTextChange = (e) => {
    setSearchText(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    handleSearch(searchText)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="row" spacing={2}
        alignItems="center">
        <TextField
          label="Reservation Code"
          variant="outlined"
          value={searchText}
          onChange={handleTextChange}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button variant="contained" color="primary" type="submit">
          Search
        </Button>
      </Stack>
    </form>
  )
}