import {
    useState,
} from 'react'

import reservationQuery from "./reservationQuery"

import { useQuery } from '@apollo/client'

import {
    Typography,
    Button,
    Box
} from '@mui/material'
import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import ImageIcon from '@mui/icons-material/Image';
import PdfViewerContent from '../../../../Components/PdfViewerContent'
import ImageDialog from '../../../../Components/ImageDialog'
import EmailFormDialog from '../EmailFormDialog'
import AlertSnackBar from '../../../../Components/AlertSnackbar'
export default ({
    reservationCode,
}) => {

    const [isImageOpen, setIsImageOpen] = useState(false)
    const [imageUrl, setImageUrl] = useState()
    const [imageTitle, setImageTitle] = useState()

    const [isEmailFormDialogOpen, setIsEmailFormDialogOpen] = useState(false)

    const { loading, error, data } = useQuery(
        reservationQuery,
        {
            variables: {
                reservationCode,
            },
        },
    )
    const [openSnackbar, setOpenSnackbar] = useState({
        open: false,
        severity: 'success',
        title: '',
        message: '',
    })
    const handleSnackbarClose = () => {
        setOpenSnackbar({
            open: false,
            severity: 'success',
            title: '',
            message: '',
        })
    }
    const downloadFile = (
        filePathUrl,
        receiptType,
        fileName = 'reservation-info-for-' + receiptType + '.pdf',
    ) => {
        try {
            const urlObject = new URL(filePathUrl)
            const path = urlObject.pathname

            const requestPrefix = process.env.NODE_ENV === "production" ?
                "" :
                "assets"

            fetch(`${requestPrefix}${path}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            })
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));

                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;

                    document.body.appendChild(link);

                    link.click();

                    link.parentNode.removeChild(link);
                });
        } catch (error) {
            console.log(error.message)
            setOpenSnackbar({
                open: true,
                severity: 'error',
                title: 'Error',
                message: 'Please try again later.',
            })
        }
    };
    return (
        <div>
            {
                loading &&
                <div>
                    <Stack alignItems="center" sx={{ p: 20 }}>
                        <CircularProgress />
                    </Stack>
                </div>
            }
            {
                error &&
                <div>
                    Error: {error.message}
                </div>
            }

            {
                data &&
                data.reservationByCode &&
                <Box elevation={3} sx={{ p: 2, }}>
                    <Stack
                        direction={{ xs: 'column', sm: 'column' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Typography variant="h5" component="div">
                            {data.reservationByCode.reservationCode}
                        </Typography>

                        <PdfViewerContent
                            url={data.reservationByCode.hostClaimReceipt}
                        />
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button variant="contained" endIcon={<ImageIcon />} onClick={() => {
                                setImageUrl(data.reservationByCode.insuranceCard.insuranceCardImages.frontImage)
                                setImageTitle("Front")
                                setIsImageOpen(true)
                            }}>
                                Insurance Front Image
                            </Button>
                            <Button variant="contained" endIcon={<ImageIcon />} onClick={() => {
                                setImageUrl(data.reservationByCode.insuranceCard.insuranceCardImages.rearImage)
                                setImageTitle("Rear")
                                setIsImageOpen(true)
                            }}>
                                Insurance Rear Image
                            </Button>
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button variant="contained" endIcon={<SendIcon />} onClick={() => { setIsEmailFormDialogOpen(true) }}>
                                Send Email
                            </Button>
                            <Button variant="outlined" endIcon={<DownloadIcon />} onClick={() => downloadFile(data.reservationByCode.guestClaimReceipt, 'guest')}>
                                Download for guest
                            </Button>
                            <Button variant="outlined" endIcon={<DownloadIcon />} onClick={() => downloadFile(data.reservationByCode.hostClaimReceipt, 'host')}>
                                Download for host
                            </Button>
                        </Stack>


                    </Stack>
                    <EmailFormDialog
                        open={isEmailFormDialogOpen}
                        onClose={() => {
                            setIsEmailFormDialogOpen(false)
                        }}
                        reservationCode={data.reservationByCode.reservationCode}
                    />
                </Box>
            }

            {
                data &&
                !data.reservationByCode &&
                <div>
                    Reservation cannot found
                </div>
            }

            <ImageDialog
                open={isImageOpen}
                onClose={() => {
                    setIsImageOpen(false)
                }}
                imageUrl={imageUrl}
                imageTitle={imageTitle}
            />
            {
                <AlertSnackBar open={openSnackbar.open} onClose={handleSnackbarClose} severity={openSnackbar.severity} title={openSnackbar.title} message={openSnackbar.message} />
            }
        </div>

    )

}