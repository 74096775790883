import {
    Authenticator,
} from '@aws-amplify/ui-react'

import '@aws-amplify/ui-react/styles.css'

import AmplifyConfig from './configs/AmplifyConfig'

import {
    Amplify,
} from 'aws-amplify'

import {
    Container,
    Button,
} from "@mui/material"

import {
    useNavigate,
} from "react-router-dom"

import Router from './router'

import {
    Fragment,
} from 'react'

import { ApolloProvider } from '@apollo/client'

import apollo from './apollo'

Amplify.configure(AmplifyConfig)

export default () => {

    const navigate = useNavigate()

    return (
        <Authenticator
            hideSignUp={true}
        >
            {({ signOut }) => (
                <Fragment>
                    <Container
                        sx={{
                            display: "flex",
                            borderBottom: '2px solid #000',
                        }}
                    >
                        <Button
                            onClick={() => { navigate('./') }}
                        >
                            ODPO Admin App
                        </Button>
                        <Button
                            onClick={signOut}
                            sx={{
                                marginLeft: "auto",
                            }}
                        >Sign Out</Button>
                    </Container>
                    <ApolloProvider client={apollo}>
                        <Router />
                    </ApolloProvider>
                </Fragment>

            )}
        </Authenticator>
    )
}