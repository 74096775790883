import {
    Routes,
    Route,
} from "react-router-dom"

import HomePage from './Pages/Home'
import ReservationPage from './Pages/Reservation'

const Router = ({

}) => {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/reservation" element={<ReservationPage />} />
        </Routes>
    )
}

export default Router