import {
    useState,
} from 'react'
import AddIcon from '@mui/icons-material/Add';
import {
    Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
export default function AddEmailFormDialog({ open, onClose }) {
    const [email, setEmail] = useState("")
    const [error, setError] = useState(null);
    const [receiptType, setReceiptType] = useState('guest');

    const handleClose = () => {
        onClose();
    }

    const handleEmailTextChange = (e) => {
        setEmail(e.target.value)
    }
    const handleReceiptType = (event) => {
        setReceiptType(event.target.value);
    }

    const handleEmailSubmit = (event) => {
        event.preventDefault()
        if (!isValidEmail(email)) {
            setError('Email is invalid');
        } else {
            setError(null);
            const emailObj = { id: Math.floor(Math.random() * Date.now()), mailId: email, receiptType: receiptType }
            onClose(emailObj)
            setEmail("")
        }
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add Mail Address</DialogTitle>
            <form onSubmit={handleEmailSubmit}>
                <DialogContent>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label" color="primary">Reservation info type</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="reservation-info-type"
                            value={receiptType}
                            name="radio-buttons-group"
                            onChange={handleReceiptType}
                        >
                            <FormControlLabel value="host" control={<Radio />} label="Host" />
                            <FormControlLabel value="guest" control={<Radio />} label="Guest" />
                        </RadioGroup>
                    </FormControl>
                    <TextField
                        autoFocus
                        margin="dense"
                        name='email'
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={handleEmailTextChange}
                        value={email}
                        error={error === null ? false : true}
                        helperText={error}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={
                        handleClose
                    }>Cancel</Button>
                    <Button
                        type='submit'
                        endIcon={<AddIcon />}
                        variant="contained"
                    >
                        <span>Add</span>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}